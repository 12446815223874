/* tslint:disable */
/* eslint-disable */
/**
 * Registration API - Authentication Token
 * This API is conceptually the one of a small micro-frontend in charge to generate and send a authentication token to a user, and then to ask them to give it back. But due to current limitations with Nuxt, the frontend part is a shared component (nuxt-authentication-token-module). The API is still hosted by registration-backend though, and it is not meant to be called by any other component. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
import type { VerificationMethod } from './';
import {
    VerificationMethodFromJSON,
    VerificationMethodFromJSONTyped,
    VerificationMethodToJSON,
} from './';

/**
 * 
 * @export
 * @interface AuthenticationTokenResponse
 */
export interface AuthenticationTokenResponse {
    /**
     * 
     * @type {VerificationMethod}
     * @memberof AuthenticationTokenResponse
     */
    verificationMethod: VerificationMethod;
    /**
     * 
     * @type {Date}
     * @memberof AuthenticationTokenResponse
     */
    expirationDateTime: Date;
}

/**
 * Check if a given object implements the AuthenticationTokenResponse interface.
 */
export function instanceOfAuthenticationTokenResponse(value: object): value is AuthenticationTokenResponse {
    if (!('verificationMethod' in value) || value['verificationMethod'] === undefined) return false;
    if (!('expirationDateTime' in value) || value['expirationDateTime'] === undefined) return false;
    return true;
}

export function AuthenticationTokenResponseFromJSON(json: any): AuthenticationTokenResponse {
    return AuthenticationTokenResponseFromJSONTyped(json, false);
}

export function AuthenticationTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'verificationMethod': VerificationMethodFromJSON(json['verificationMethod']),
        'expirationDateTime': (new Date(json['expirationDateTime'])),
    };
}

export function AuthenticationTokenResponseToJSON(value?: AuthenticationTokenResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'verificationMethod': VerificationMethodToJSON(value['verificationMethod']),
        'expirationDateTime': ((value['expirationDateTime']).toISOString()),
    };
}

