/* tslint:disable */
/* eslint-disable */
/**
 * Registration API - Authentication Token
 * This API is conceptually the one of a small micro-frontend in charge to generate and send a authentication token to a user, and then to ask them to give it back. But due to current limitations with Nuxt, the frontend part is a shared component (nuxt-authentication-token-module). The API is still hosted by registration-backend though, and it is not meant to be called by any other component. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const VerificationMethod = {
    Email: 'EMAIL'
} as const;
export type VerificationMethod = typeof VerificationMethod[keyof typeof VerificationMethod];


export function instanceOfVerificationMethod(value: any): boolean {
    for (const key in VerificationMethod) {
        if (Object.prototype.hasOwnProperty.call(VerificationMethod, key)) {
            // @ts-ignore
            if (VerificationMethod[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function VerificationMethodFromJSON(json: any): VerificationMethod {
    return VerificationMethodFromJSONTyped(json, false);
}

export function VerificationMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerificationMethod {
    return json as VerificationMethod;
}

export function VerificationMethodToJSON(value?: VerificationMethod | null): any {
    return value as any;
}

