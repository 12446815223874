<template>
    <VJoyDialog
        ref="dialog"
        data-testid="authentication-token-popup"
        :confirm-text="$t('authentication.token.popup.submit.action')"
        :cancel-text="$t('authentication.token.popup.cancel.action')"
        @dialog:confirm="onConfirm"
        @dialog:hide="onHide"
    >
        <template #dialog-header>
            {{ $t('authentication.token.popup.title') }}
        </template>

        <template #dialog-body>
            <p v-if="verificationMethod === 'EMAIL'" data-testid="authentication-token-prompt">
                {{ $t('authentication.token.popup.body.email.method.prompt') }}
            </p>
            <div v-if="!isInputDigitsInAllTouchPointsEnabled">
                <VJoyInput
                    v-model="tokenModel"
                    data-testid="authentication-token-input"
                    name="token"
                    :invalid="v$.token.$invalid"
                    :placeholder="$t('authentication.token.popup.token.input.placeholder')"
                />
                <VJoyFormError v-if="v$.token.$invalid" data-testid="authentication-token-error">
                    {{ $t('authentication.token.popup.error.invalid.token') }}
                </VJoyFormError>
            </div>
            <MaltOtpInput v-else v-model="tokenModel" :error-message="errorMessage" data-testid="authentication-token-input" />
            <p data-testid="authentication-token-expiration-note">
                {{ $t('authentication.token.popup.body.expiration.note', [formattedExpirationTime]) }}
            </p>
            <pre v-if="developerHelp">{{ developerHelp }}</pre>
        </template>
    </VJoyDialog>
</template>

<script setup lang="ts">
    import MaltOtpInput from '@malt/joy-entrypoint/src/vue/plugins/external/components/MaltOtpInput/MaltOtpInput.vue';
    import {useFeatureFlag, useTranslation} from '#imports';
    import {computed, reactive, ref} from 'vue';
    import {VJoyDialog, VJoyFormError, VJoyInput} from '@maltjoy/core-vue';
    import {type Deferred, deferred} from '#malt/nuxt-utils-module';
    import {helpers, required} from '@vuelidate/validators';
    import {useVuelidate} from '@vuelidate/core';
    import {AuthenticationTokenApi, type AuthenticationTokenResponse, Configuration, FetchError} from '@authentication-token-api';

    defineExpose({
        requestVerificationToken,
    });

    const {t} = useTranslation();
    const {isFeatureEnabled} = useFeatureFlag();
    const isInputDigitsInAllTouchPointsEnabled = await isFeatureEnabled('client-input-digits-in-all-touch-points');

    const api = new AuthenticationTokenApi(new Configuration({basePath: ''}));

    const timeFormat = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
    });

    const developerHelp = ref('');
    const dialog = ref<InstanceType<typeof VJoyDialog>>();
    const verificationMethod = ref<string>();
    const formattedExpirationTime = ref<string>();

    const tokenModel = ref('');

    const errorMessage = computed(() => (v$.value.token.$invalid ? t('authentication.token.popup.error.invalid.token') : ''));

    const rules = {
        token: {required, sixDigits: helpers.regex(/^\d{6}$/)},
    };
    const state = reactive({token: tokenModel});
    const v$ = useVuelidate(rules, state, {$lazy: true, $stopPropagation: true});

    export type Result = {
        canceled: boolean;
        tokenEnteredByUser?: string;
    };

    let deferredUserInput: Deferred<Result>;

    async function onConfirm() {
        await v$.value.$validate();
        if (v$.value.$invalid) {
            return;
        }

        deferredUserInput.resolve({
            canceled: false,
            tokenEnteredByUser: tokenModel.value!!,
        });

        await dialog.value?.hide();
        onClose();
    }

    function onClose() {
        tokenModel.value = '';
        v$.value.$reset();
    }

    function onHide() {
        deferredUserInput.resolve({
            canceled: true,
        });
        onClose();
    }

    async function requestVerificationToken(operation: string, email?: string): Promise<Result> {
        deferredUserInput = deferred();

        let tokenResponse: AuthenticationTokenResponse;
        try {
            tokenResponse = await api.requestAuthenticationToken({
                authenticationTokenRequest: {operation, email},
            });
        } catch (e: unknown) {
            if (!(e instanceof FetchError)) {
                throw e;
            }
            if (e.statusCode === 501) {
                // feature is disabled
                return Promise.resolve({
                    canceled: false,
                    tokenEnteredByUser: '424242', // irrelevant
                });
            } else if (e.statusCode === 502 && (window.location.hostname.match(/^dev[^.]*\.malt\./) || window.location.hostname === 'localhost')) {
                developerHelp.value =
                    'It looks like registration-backend is not responding,\n' +
                    'falling back to a mocked behavior.\n' +
                    'Use the following tokens to test authentication:\n' +
                    '\t222222: already consumed\n' +
                    '\t111111: expired\n' +
                    '\t123456: valid token\n' +
                    '\t260213: valid token too :-P\n' +
                    'Any other token will be considered invalid.';

                tokenResponse = {
                    verificationMethod: 'EMAIL',
                    expirationDateTime: new Date(Date.now() + 5 * 60 * 1000),
                };
            } else {
                throw e;
            }
        }

        if (tokenResponse.verificationMethod !== 'EMAIL') {
            throw new Error(`Unsupported verification method: ${tokenResponse.verificationMethod}`);
        }

        verificationMethod.value = tokenResponse.verificationMethod;
        formattedExpirationTime.value = timeFormat.format(tokenResponse.expirationDateTime);

        await dialog.value?.show();

        return deferredUserInput.promise;
    }
</script>
