<template>
    <AuthenticationTokenPopup ref="authenticationTokenPopup" />
    <NuxtLayout name="default">
        <template #header-right>
            <VJoyButton size="small" variant="secondary" :href="signupHref" data-testid="signup-button">
                {{ $t('registration.header.cta.signup') }}
            </VJoyButton>
        </template>
        <main class="signin">
            <Highlight>
                <strong>{{ $t('registration.signin.title') }}</strong>
            </Highlight>
            <div class="signin__social-btn">
                <VJoyButton variant="secondary" data-testid="google-signin-button" @click="signinWithOAuth2Provider('google')">
                    <MaltImg src="/social-networks/signin-google" alt="" height="18" />
                    <span>{{ $t('registration.signin.cta.login.google.label') }}</span>
                </VJoyButton>
                <VJoyButton variant="secondary" data-testid="facebook-signin-button" @click="signinWithOAuth2Provider('facebook')">
                    <MaltImg src="/social-networks/signin-facebook-v2.svg?vh=ece40a" alt="" height="18" />
                    <span>{{ $t('registration.signin.cta.login.facebook.label') }}</span>
                </VJoyButton>
                <VJoyButton variant="secondary" data-testid="sso-signin-button" @click="signinWithSso">
                    <MaltImg src="/social-networks/signin-sso-v2.svg?vh=c3e74e" alt="" height="18" />
                    <span>{{ $t('registration.signin.cta.login.sso.label') }}</span>
                </VJoyButton>
                <VJoyButton v-if="!isDisplayMobile" variant="secondary" data-testid="punchout-signin-button" @click="signinWithPunchout">
                    <MaltImg src="/social-networks/punchout.svg?vh=883f3e" alt="" height="18" />
                    <span>{{ $t('registration.signin.cta.login.punchout.label') }}</span>
                </VJoyButton>
            </div>
            <VJoySeparator :text="$t('registration.signin.separator.or')" size="small" />
            <VJoyFormError v-if="authenticationError">
                {{ authenticationError }}
            </VJoyFormError>
            <VJoyFormError v-if="socialLoginDisabledForSsoAccounts">
                {{ $t('registration.signin.error.social.login.disabled.for.sso.accounts') }}
            </VJoyFormError>
            <!-- Please keep the method here: although it's not used by the script, it will prevent the
                 form parameters to appear in the address bar in case the user submits the form while
                 the script is not loaded yet.
            -->
            <form id="signin-form" class="signin__inputs" novalidate method="post">
                <EmailInput
                    v-model="emailModel"
                    :readonly="!isHydrated"
                    :autofocus="!isDisplayMobile"
                    :invalid="v$.email.$invalid || !!authenticationError"
                    data-testid="signin-email-input"
                    data-vuelidate-error="email"
                />
                <VJoyFormError v-if="v$.email.required.$invalid">
                    {{ $t('registration.signin.email.error.required') }}
                </VJoyFormError>
                <VJoyFormError v-if="v$.email.email.$invalid">
                    {{ $t('registration.signin.email.error.invalid') }}
                </VJoyFormError>
                <template v-if="signinMethod === 'EMAIL_AND_PASSWORD'">
                    <VJoyInput
                        v-model="passwordModel"
                        class="signin__password"
                        name="password"
                        type="password"
                        :readonly="!isHydrated"
                        :placeholder="$t('registration.signin.input.password.placeholder')"
                        :invalid="v$.password.$invalid || !!authenticationError"
                        data-testid="signin-password-input"
                        data-vuelidate-error="password"
                    />
                    <VJoyFormError v-if="v$.password.$invalid">
                        {{ $t('registration.signin.password.error.required') }}
                    </VJoyFormError>
                </template>
            </form>
            <button
                v-if="signinMethod === 'EMAIL_AND_PASSWORD'"
                class="signin__pwd-link"
                type="button"
                data-testid="password-renewal-link"
                @click="onPasswordRenewalRequest"
            >
                {{ $t('registration.signin.link.forgot.password') }}
            </button>
            <div v-if="existingFreelancerSignupWorkflowRedirectionUrl" class="signin__ongoing-freelancer-signup">
                <VJoyHighlight :display-icon="true" icon="question-circle" level="warning">
                    {{ $t('registration.signin.ongoing.freelancer.signup.description') }}
                    <VJoyLink
                        :href="existingFreelancerSignupWorkflowRedirectionUrl"
                        :text="$t('registration.signin.ongoing.freelancer.signup.cta.label')"
                        data-testid="continue-freelancer-signup-link"
                    />
                </VJoyHighlight>
            </div>
            <div v-show="signinMethod === 'UNCLAIMED_COMATCH_ACCOUNT'" class="signin__comatch" data-testid="signin-comatch-panel">
                <MaltImg src="/registration-front/happy-malty.png?vh=025712" alt="Happy Malty" height="82" />
                <div>
                    <h2>{{ $t('registration.signin.unclaimed.comatch.account.title') }}</h2>
                    <p>{{ $t('registration.signin.unclaimed.comatch.account.text') }}</p>
                </div>
            </div>

            <ClientOnly v-if="shouldDisplayCloudflareTurnstile">
                <div class="signin__turnstile-container">
                    <NuxtTurnstile
                        v-model="cloudflareTokenModel"
                        :options="{language: locale.toLocaleLowerCase(), theme: 'light'}"
                        class="signin__turnstile"
                    />
                </div>

                <template #fallback>
                    <div class="signin__turnstile-empty-area" />
                </template>
            </ClientOnly>

            <p
                :class="{signin__conditions: true, 'signin__conditions-small': shouldDisplayCloudflareTurnstile}"
                v-html="$t('registration.signin.link.malt.conditions')"
            ></p>
        </main>
        <template #footer-left>
            <VJoyButton variant="ghost" @click.prevent="back">
                {{ $t('registration.signin.cta.back') }}
            </VJoyButton>
        </template>
        <template #footer-right>
            <VJoyButton
                type="submit"
                form="signin-form"
                variant="main"
                :loading="pendingRequest"
                data-testid="signin-submit"
                @click.prevent="onSubmit"
            >
                <template v-if="signinMethod === 'EMAIL_AND_PASSWORD'">{{ $t('registration.signin.cta.login') }}</template>
                <template v-if="signinMethod === 'UNCLAIMED_COMATCH_ACCOUNT'">
                    {{ $t('registration.signin.cta.login.comatch') }}
                </template>
                <template v-if="signinMethod === 'SSO'">
                    {{ $t('registration.signin.cta.login.sso', [ssoOrganizationName]) }}
                </template>
            </VJoyButton>
        </template>
    </NuxtLayout>
</template>

<script setup lang="ts">
    import {AuthenticationTokenPopup, MaltImg, NuxtTurnstile} from '#components';
    import {
        definePageMeta,
        navigateTo,
        OpenGraphMetaTag,
        TwitterMetaTags,
        useFeatureFlag,
        useHydrationState,
        useLocale,
        useLogger,
        useNuxtApp,
        useOpenGraphMetaTags,
        useRequestURL,
        useRoute,
        useSeoMeta,
        useSignupAttributesPropagation,
        useState,
        useTranslation,
        useTwitterMetaTags,
    } from '#imports';
    import {safelyNavigateTo, useDisplayMobile} from '#malt/nuxt-utils-module';
    import {useVuelidate} from '@vuelidate/core';
    import {email, required} from '@vuelidate/validators';
    import {storeToRefs} from 'pinia';
    import {computed, reactive, ref, watch} from 'vue';
    import EmailInput from '~/components/EmailInput.vue';
    import Highlight from '~/components/Highlight.vue';
    import {usePasswordRenewalStore} from '~/store/password-renewal.store';
    import {useSigninStore} from '~/store/signin.store';
    import {VJoyButton, VJoyFormError, VJoyHighlight, VJoyInput, VJoyLink, VJoySeparator} from '@maltjoy/core-vue';
    import type {FetchError} from '@api';

    definePageMeta({
        layout: false,
    });

    const {isHydrated} = useHydrationState();

    const {isDisplayMobile} = useDisplayMobile('Xs');
    const logger = useLogger();
    const route = useRoute();
    const {locale} = useLocale();

    const shouldDisplayCloudflareTurnstile = useState<boolean>('shouldDisplayCloudflareTurnstile', () => false);
    if (import.meta.server) {
        const nuxtApp = useNuxtApp();
        const {isFeatureEnabled} = useFeatureFlag();
        const isUsingCloudflareTurnstile = await isFeatureEnabled('use-cloudflare-turnstile');
        shouldDisplayCloudflareTurnstile.value = isUsingCloudflareTurnstile && (nuxtApp.ssrContext?.event?.context?.turnstile ?? false);
    }

    const {t} = useTranslation();
    const title = t('registration.signin.meta.title');

    useSeoMeta({title});
    useOpenGraphMetaTags(new OpenGraphMetaTag({title, t}));
    useTwitterMetaTags(new TwitterMetaTags({card: 'summary', title, t}));

    const {addPageSourceParamToUrl} = useSignupAttributesPropagation();

    const signupHref = addPageSourceParamToUrl(withCurrentRedirectParam('/who-are-you'));

    const signinInStore = useSigninStore();
    const {
        signinMethod,
        authenticationError,
        existingFreelancerSignupWorkflowEmail,
        emailModel,
        passwordModel,
        cloudflareTokenModel,
        pendingRequest,
        redirectTo,
        ssoOrganizationName,
    } = storeToRefs(signinInStore);
    const {signinWithAuthenticationDetails, checkExistingSignupWorkflowEmail} = signinInStore;

    const passwordRenewalStore = usePasswordRenewalStore();
    const {requestNewPassword} = passwordRenewalStore;

    const rules = computed(() => ({
        email: {
            required,
            email,
        },
        password: {
            required: signinMethod.value === 'EMAIL_AND_PASSWORD' ? required : false,
        },
        cloudflareToken: shouldDisplayCloudflareTurnstile.value
            ? {
                  required,
              }
            : {},
    }));
    const state = reactive({email: emailModel, password: passwordModel, cloudflareToken: cloudflareTokenModel});
    const v$ = useVuelidate(rules, state, {$lazy: true});

    const existingFreelancerSignupWorkflowRedirectionUrl = computed<string | null>(() => {
        const email = existingFreelancerSignupWorkflowEmail.value;
        return email ? `/profile/freelancer-signup/welcome-back?email=${encodeURIComponent(email)}` : null;
    });

    if (typeof route.query['no-such-account'] === 'string') {
        navigateTo(addPageSourceParamToUrl(withCurrentRedirectParam(`/signin/unknown-account?email=${route.query['no-such-account']}`)));
    }

    const socialLoginDisabledForSsoAccounts = ref(false);
    if (route.query.error === 'social_login_disabled_for_sso_accounts') {
        socialLoginDisabledForSsoAccounts.value = true;
    }

    const authenticationTokenPopup = ref<InstanceType<typeof AuthenticationTokenPopup>>();
    const shoudAsk2FAOnSignin = useState<boolean>('shoudAsk2FAOnSignIn', () => false);
    const {isFeatureEnabled} = useFeatureFlag();
    shoudAsk2FAOnSignin.value = await isFeatureEnabled('2FA-on-signin');

    watch(emailModel, () => {
        socialLoginDisabledForSsoAccounts.value = false;
    });

    function back() {
        if (document.referrer && document.referrer.includes(window.location.host)) {
            history.back();
        } else {
            navigateTo('/', {external: true});
        }
    }

    async function onPasswordRenewalRequest() {
        try {
            await checkExistingSignupWorkflowEmail(emailModel.value);
        } catch (e) {
            const error = e as FetchError;
            if (error.statusCode !== 404) {
                logger.error(error);
            }
        }
        if (!existingFreelancerSignupWorkflowEmail.value) {
            await requestNewPassword();
        }
    }

    function scrollIntoFirstInputWithError() {
        const firstError = v$.value.$errors[0];
        const input = document.querySelector(`input[data-vuelidate-error="${firstError.$property}"]`) as HTMLInputElement;
        if (!input) {
            return;
        }
        input.scrollIntoView();
        input.focus();
    }

    async function onSubmit() {
        if (signinMethod.value === 'EMAIL_AND_PASSWORD') {
            await v$.value.$validate();
            if (v$.value.$invalid) {
                scrollIntoFirstInputWithError();
                return;
            }
            if (shoudAsk2FAOnSignin.value) {
                const operation = 'signin.signinWithAuthenticationDetails';

                const {canceled, tokenEnteredByUser} = await authenticationTokenPopup.value!!.requestVerificationToken(operation, emailModel.value);
                if (canceled) {
                    return;
                }
                const {error} = await signinWithAuthenticationDetails(tokenEnteredByUser);
                if (error) {
                    return;
                }
            } else {
                const {error} = await signinWithAuthenticationDetails();
                if (error) {
                    return;
                }
            }
        }
        await redirectToExternal();
    }

    function redirectToExternal() {
        if (redirectTo.value) {
            // We're fine with redirecting the user to another one of our hosts only, but not to
            // any other hosts (to prevent Open Redirect attacks).
            // That being said, it doesn't make any sense to so, since the user wouldn't be logged
            // in on the host they are redirected to.
            safelyNavigateTo(redirectTo.value, {externalToThisApp: true});
        } else {
            logger.error(`No redirect URL provided for signin method: ${signinMethod.value} (email: ${signinMethod.value})`);
        }
    }

    function withCurrentRedirectParam(url: string) {
        const currentUrl = useRequestURL();

        const redirectParam = currentUrl.searchParams.get('redirect');
        if (redirectParam) {
            return `${url}${url.includes('?') ? '&' : '?'}redirect=${encodeURIComponent(redirectParam)}`;
        }

        return url;
    }

    function redirectToAuthUrl(authUrl: string) {
        const targetUrl = withCurrentRedirectParam(authUrl);
        safelyNavigateTo(targetUrl, {externalToThisApp: true});
    }

    function signinWithOAuth2Provider(provider: string) {
        redirectToAuthUrl(
            addPageSourceParamToUrl(`/registration/oauth2/authorization/${provider}?actionType=signin&errRedirect=${encodeURIComponent(route.path)}`),
        );
    }

    function signinWithSso() {
        redirectToAuthUrl('/sso/auth/signin');
    }

    function signinWithPunchout() {
        redirectToAuthUrl('/punchout/auth/signin');
    }
</script>

<style lang="scss">
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    @mixin link {
        color: var(--joy-color-secondary-30);
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    input.signin__password::-moz-placeholder {
        letter-spacing: 0px;
        font-size: var(--joy-font-size-primary-300);
    }

    .signin {
        .highlight {
            strong {
                margin-left: calc(var(--joy-core-spacing-2) * -1);

                @include mq.screen_xs {
                    margin-left: 0;
                }
            }
        }

        &__social-btn {
            display: flex;
            flex-direction: column;
            gap: var(--joy-core-spacing-4);

            > .joy-button {
                width: 100%;

                .joy-button--slot {
                    display: flex;
                    align-items: center;
                    gap: var(--joy-core-spacing-2);
                }
            }
        }

        .joy-separator {
            margin-top: var(--joy-core-spacing-2);
            margin-bottom: var(--joy-core-spacing-2);
        }

        &__inputs {
            .joy-input--medium:last-child {
                .joy-input--wrapper {
                    margin-bottom: 0;
                }
            }
        }

        &__pwd-link {
            @include link;

            background: none;
            border: none;
            outline: none;
            padding: 0;
            margin-top: var(--joy-core-spacing-2);
            margin-left: var(--joy-core-spacing-5);
            cursor: pointer;
            font-size: var(--joy-font-size-primary-200);
        }

        &__comatch {
            display: flex;
            align-items: center;
            margin-top: var(--joy-core-spacing-5);
            padding: var(--joy-core-spacing-4);

            > img {
                height: 82px;
                margin-right: var(--joy-core-spacing-4);
            }

            h2 {
                font-size: var(--joy-font-size-primary-400);
                margin-bottom: var(--joy-core-spacing-1);
            }

            p {
                font-size: var(--joy-font-size-primary-300);
            }
        }

        &__conditions {
            margin-top: var(--joy-core-spacing-10);

            &-small {
                margin-top: var(--joy-core-spacing-4);
            }

            > a {
                @include link;
            }
        }

        &__ongoing-freelancer-signup {
            margin-top: var(--joy-core-spacing-5);
        }

        &__turnstile {
            &-empty-area {
                height: calc(63px + var(--joy-core-spacing-4));
            }

            &-container {
                height: 63px;
                display: flex;
                margin: var(--joy-core-spacing-4) 0 0 0;
            }
        }
    }
</style>
