
/* tslint:disable */
/* eslint-disable */
/**
 * Registration API - Authentication Token
 * This API is conceptually the one of a small micro-frontend in charge to generate and send a authentication token to a user, and then to ask them to give it back. But due to current limitations with Nuxt, the frontend part is a shared component (nuxt-authentication-token-module). The API is still hosted by registration-backend though, and it is not meant to be called by any other component. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  AuthenticationTokenRequest,
  AuthenticationTokenResponse,
} from '../models';
import {
    AuthenticationTokenRequestFromJSON,
    AuthenticationTokenRequestToJSON,
    AuthenticationTokenResponseFromJSON,
    AuthenticationTokenResponseToJSON,
} from '../models';

export interface RequestAuthenticationTokenRequest {
    authenticationTokenRequest: AuthenticationTokenRequest;
}

/**
 * 
 */
export class AuthenticationTokenApi extends runtime.BaseAPI {

    /**
     * Create and send an authentication token to the given account
     */
    async requestAuthenticationTokenRaw(requestParameters: RequestAuthenticationTokenRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<AuthenticationTokenResponse>> {
        if (requestParameters['authenticationTokenRequest'] == null) {
            throw new runtime.RequiredError(
                'authenticationTokenRequest',
                'Required parameter "authenticationTokenRequest" was null or undefined when calling requestAuthenticationToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<AuthenticationTokenResponse>({
            path: `/registration/api/authentication-token-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticationTokenRequestToJSON(requestParameters['authenticationTokenRequest']),
        }, initOverrides);

        if (response._data) { response._data=AuthenticationTokenResponseFromJSON(response._data); }
        return response;
    }

    /**
     * Create and send an authentication token to the given account
     */
    async requestAuthenticationToken(requestParameters: RequestAuthenticationTokenRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<AuthenticationTokenResponse> {
        const response = await this.requestAuthenticationTokenRaw(requestParameters, initOverrides);
        return response._data;
    }

}
