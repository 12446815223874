<template>
    <div>
        <VJoyLabel v-if="label">{{ label }}</VJoyLabel>
        <InputOtp v-model="modelValue" :length="length" :invalid="hasErrorMessage" :disabled="disabled" integer-only>
            <template #default="{attrs, events, index}">
                <input
                    v-bind="attrs"
                    v-on="events"
                    type="numeric"
                    :name="`otp-input-${index}`"
                    :disabled="disabled"
                    :class="{'otp-input__invalid': hasErrorMessage}"
                    class="otp-input"
                />
            </template>
        </InputOtp>
        <VJoyFormError v-if="hasErrorMessage" data-testid="otp-input-error">{{ errorMessage }}</VJoyFormError>
    </div>
</template>

<script setup lang="ts">
    import {VJoyLabel, VJoyFormError} from '@maltjoy/core-vue';
    import {computed} from 'vue';
    import InputOtp from 'primevue/inputotp';

    interface Props {
        label?: string;
        length?: number;
        errorMessage?: string;
        disabled?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        length: 6,
        disabled: false,
    });

    const modelValue = defineModel<string>();

    const hasErrorMessage = computed(() => Boolean(props.errorMessage));
</script>

<style lang="scss" scoped>
    .p-inputotp {
        gap: var(--joy-core-spacing-2);
    }

    .otp-input {
        width: 40px;
        border-radius: var(--joy-core-radius-3);
        height: 56px;

        height: var(--joy-form-field-height-medium);
        border: var(--joy-form-border-width) solid var(--joy-color-neutral-30);
        color: var(--joy-color-neutral-60);
        font-size: var(--joy-font-size-primary-300);
        padding: var(--joy-core-spacing-3) var(--joy-core-spacing-3) var(--joy-core-spacing-3) var(--joy-core-spacing-4);
        margin-bottom: var(--joy-core-spacing-2);

        &:enabled:focus,
        &:enabled:hover {
            box-shadow: none;
            border-color: var(--joy-color-state-hover);
        }

        &__invalid {
            border-color: var(--joy-color-error-50);
            color: var(--joy-color-error-50);

            &:hover {
                border-color: var(--joy-color-error-90);
            }
        }
    }
</style>
