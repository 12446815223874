/* tslint:disable */
/* eslint-disable */
/**
 * Registration API - Authentication Token
 * This API is conceptually the one of a small micro-frontend in charge to generate and send a authentication token to a user, and then to ask them to give it back. But due to current limitations with Nuxt, the frontend part is a shared component (nuxt-authentication-token-module). The API is still hosted by registration-backend though, and it is not meant to be called by any other component. 
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationTokenRequest
 */
export interface AuthenticationTokenRequest {
    /**
     * Describes the sensitive operation that requires authentication.
     * @type {string}
     * @memberof AuthenticationTokenRequest
     */
    operation: string;
    /**
     * The email of the account we want to generate an authentication token for, in case the user is not connected for the use case (e.g. for sign-in).
     * @type {string}
     * @memberof AuthenticationTokenRequest
     */
    email?: string;
}

/**
 * Check if a given object implements the AuthenticationTokenRequest interface.
 */
export function instanceOfAuthenticationTokenRequest(value: object): value is AuthenticationTokenRequest {
    if (!('operation' in value) || value['operation'] === undefined) return false;
    return true;
}

export function AuthenticationTokenRequestFromJSON(json: any): AuthenticationTokenRequest {
    return AuthenticationTokenRequestFromJSONTyped(json, false);
}

export function AuthenticationTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationTokenRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'operation': json['operation'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function AuthenticationTokenRequestToJSON(value?: AuthenticationTokenRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'operation': value['operation'],
        'email': value['email'],
    };
}

