export type Deferred<T> = {
    promise: Promise<T>;
    resolve: (value: T | PromiseLike<T>) => void;
    reject: (reason?: any) => void;
};

/**
 * How to use:
 * <pre>
 *     // in a first piece of code
 *
 *     let deferredResult: Deferred<Number>;
 *
 *     function onSomeEvent() {
 *          deferredResult.resolve(42);
 *     }
 *
 *     function computeResult(): Promise<Number> {
 *          deferredResult = deferred();
 *
 *          triggerSomethingThatShouldEventuallyFireTheAboveEvent();
 *
 *          return deferredResult.promise;
 *     }
 *
 *
 *     // in another piece of code
 *
 *     const resultNumber = await computeResult();
 * </pre>
 */
export function deferred<T>(): Deferred<T> {
    const deferred = {} as Deferred<T>;
    deferred.promise = new Promise(function (resolve, reject) {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });
    return deferred;
}
