<template>
    <VJoyInput
        v-model="modelValue"
        name="email"
        type="email"
        :placeholder="$t('registration.password.renewal.input.email.placeholder')"
        :autofocus="autofocus"
        :invalid="invalid"
    />
</template>

<script setup lang="ts">
    import {useVuelidate} from '@vuelidate/core';
    import {email, required} from '@vuelidate/validators';
    import debounce from 'lodash-es/debounce';
    import {storeToRefs} from 'pinia';
    import {reactive, watch, computed} from 'vue';
    import {useRoute} from 'nuxt/app';
    import {useSigninStore} from '~/store/signin.store';
    import {VJoyInput} from '@maltjoy/core-vue';

    interface Props {
        invalid: boolean;
        autofocus?: boolean;
    }

    defineProps<Props>();

    const modelValue = defineModel<string>();

    const signinInStore = useSigninStore();
    const route = useRoute();

    const {emailModel} = storeToRefs(signinInStore);
    const {getSigninMethodsRestrictions, resetSigninMethod} = signinInStore;

    const rules = computed(() => ({
        email: {
            email,
            required,
        },
    }));
    const state = reactive({email: emailModel});
    const v$ = useVuelidate(rules, state, {$stopPropagation: true});

    const getSigninMethodsRestrictionsDebounced = debounce(() => {
        if (v$.value.email.$invalid) {
            resetSigninMethod();
            return;
        }
        getSigninMethodsRestrictions(route.query.redirect as string);
    }, 250);

    watch(emailModel, () => {
        getSigninMethodsRestrictionsDebounced();
    });
</script>
